.giftCardSection {
    width: 100%;
    height: 100vh;
    position: relative;
}

.comingSoonImage {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25rem;
}