.headingOne {
    color: #E2D7A6;
    font-size: 5rem;
}

/* ---------- Media Queries -------- */
@media screen and (max-width: 70rem) {
    .headingOne {
        font-size: 4rem;
    }
}

@media screen and (max-width: 50rem) {
    .headingOne {
        font-size: 2.8rem;
    }
}