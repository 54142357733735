.baseButton {
    display: inline-block;
    width: fit-content;
    padding: 1rem 1rem;
    border: 3px solid black;
    border-radius: 5px;

	background-color: white;
	color: black;
	font-family: sans-serif;
	font-size: 1rem;
	
	position: relative;
	transition: all 1s;
	overflow: hidden;
    
}

.baseButton p {
   margin: 0;
   position: relative;
   z-index: 3;
}

.baseButton:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #E2D7A6;
	z-index: 1;
	transform: translateY(-100%);
	transition: all 1s;
}

.baseButton:hover:before {
	transform: translateY(0);
}

.baseButton:hover {
	color: black;
    cursor: pointer;
}