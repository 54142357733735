.desktopNavBar {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: fit-content;
    padding: 1rem;
    box-sizing: border-box;
    background-color: black;

    /* Grid */
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.logoContainer {
    display: inline-block;
    text-align: left;
    grid-column: 1 / 2;
}

.logo {
    width: 10rem;
}

.navOptionsContainer {
    display: inline-block;
    grid-column: 2 / 6;
}

.navOptionsContainerSecondary {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 2rem 0;
}

.navigationOption {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    margin: 0.3rem 1rem;
    display: inline-block;
    transition: all 0.2s;
}

.navigationOption:hover {
    color: #E2D7A6;
    font-weight: 600;
}

.navButtonContainer {
    display: inline-block;
    text-align: right;
    grid-column: 6 / 7;
}

.navButtonContainerSecondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
} 

/* ---------- Media Queries -------- */

@media screen and (max-width: 70rem) {

}

@media screen and (max-width: 57rem) {
    .desktopNavBar {
        display: none;
    }
}