.menuBanner {
    width: 100%;
    height: fit-content;
    text-align: center;
    /* background-image: linear-gradient(rgba(216, 216, 216, 0.8), rgba(155, 155, 155, 0.2)), url("../../assets/images/ribs.jpg"); */
    background-image: url("../../assets/images/ribs.jpg");
    background-position: center; 
    background-size: cover;
    padding: 12rem 5rem 5rem 5rem;
    box-sizing: border-box;
}

.menuTitle {
    margin: 0;
    color: white;
}

.menuDescription {
    color: white;
    margin: 0;
}