.mediaPage {
    padding: 8rem 0 0 0;
    text-align: center;
}

.mediaPageTitle {
    margin: 2rem auto;
}

.mediaContent {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
}

.mediaContentColumn {
    /* border: 2px solid red; */
    width: 95%;
    display: inline-block;
    margin: 0 auto;
}

.pictureFrame {
    width: 100%;
    margin: 2rem auto;
    border-radius: 5px;

    position: relative;
    overflow: hidden; 
    box-shadow: 5px 5px 10px #1d1d1d;
}

.pictureFrame img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    position: absolute;
    top: 0;
    left: 0;
}

.squarePicture {
    height: 22rem;
}

.verticalPicture {
    height: 30rem;
}

.horizontalPicture {
    height: 18rem;
}

/* --- Media Queries --- */
@media screen and (max-width: 80rem) {
    .mediaContent {
        grid-template-columns: repeat(2, 1fr);
    }

    .rightColumn {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        grid-column: 1 / 3;
        display: grid;
        grid-row-gap: -1rem;
        grid-column-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
    }

    .rightColumn .pictureFrame {
        margin: 1rem auto;
    }
}

@media screen and (max-width: 50rem) {
    .mediaContent {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    } 

    .rightColumn {
        display: inline-block;
        grid-column: 1 / 2;
    }
}