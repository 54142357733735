.customerReviewCard {
    width: 15rem;
    height: 18rem;
    padding: 2rem 1rem;
    background-color: white;
    border-radius: 5px;
    margin: auto;
    font-size: 1rem;
    box-shadow: 5px 5px 10px #1d1d1d;
}

.personImgContainer {
    height: 7rem; 
    width: 7rem; 
    border-radius: 50%;
    border: 3px solid white;
    overflow: hidden;
    margin: auto;

    /* Child image adjustment */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.personImgContainer img {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

.starContainer {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    margin: auto;
}

.starImageContainer {
    height: 1.5rem; 
    width: 1.5rem; 
    overflow: hidden;

    /* Child image adjustment */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.starImageContainer img {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .customerReviewCard {
        width: 20rem;
        height: fit-content;
        padding: 2rem 1rem;
        background-color: white;
        border-radius: 5px;
        margin: auto;
        font-size: 1rem;
    }
}