.headingTwo {
    font-size: 3rem;
}

/* ---------- Media Queries -------- */
@media screen and (max-width: 70rem) {
    .headingTwo {
        font-size: 2rem;
    }
}

@media screen and (max-width: 50rem) {
    .headingTwo {
        font-size: 1.8rem;
    }
}