.joinTheTeamPage {
    padding: 8rem 0 0 0;
}

.contentContainer {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(15, 1fr);
    align-items: center;
}

/* Left Content */

.leftContent {
    width: 90%;
    margin: 3rem auto;

    grid-column: 1 / 11;
}

.teamIntro {
    margin: 0 3rem;
}

.waitressImageContainerMobile {
    display: none;
    width: 100%;
    text-align: center;
}

.waitressImageMobile {
    width: 90%;
    border-radius: 20px;
}

.formContainer {
    width: 100%;
    font-size: 1.5rem;
}

.nameContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.inputSection {
    display: block;
    margin: 1rem 0;
}

.fullSizeInput {
    width: 95%;
    height: 3rem;
    font-size: 1.5rem;
}

.messageTextArea {
    font-size: 1.5rem;
    width: 95%;
    height: 7rem;
}

.formSubmitButton {
    display: block;
    min-width: fit-content;
    width: 20rem;
    margin: 2rem auto;
    padding: 1rem 0;
    border: 3px solid white;
    border-radius: 5px;
	background-color: black;

	color: white;
	text-align: center;
	font-family: sans-serif;
	font-size: 1rem;
	
	position: relative;
	transition: all 1s;
	overflow: hidden;
    
}

.formSubmitButton:hover {
    cursor: pointer;
} 

/* Right Content */
.rightContent {
    width: fit-content;
    margin: 0;
    grid-column: 11 / 16;
}

.staffImageContainer {
    position: relative;
    width: 20rem;
    height: 50rem;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 5px 5px 10px #1d1d1d;
}

.staffImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    position: absolute;
    top: 0;
    left: 0;
}

/* --- Media Queries --- */
@media screen and (max-width: 80rem) {

}

@media screen and (max-width: 65rem) {
    .contentContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .leftContent {
        grid-column: 1 / 2;
    }

    .waitressImageContainerMobile {
        display: inline-block;
    }

    .rightContent {
        display: none;
    }
}

@media screen and (max-width: 50rem) {

}