.buttonTwo {
    display: block;
    min-width: fit-content;
    width: 20rem;
    margin: 2rem auto;
    padding: 1rem 0;
    border: 3px solid white;
    border-radius: 5px;
	background-color: black;

	color: white;
	text-align: center;
	font-family: sans-serif;
	font-size: 1rem;
	
	position: relative;
	transition: all 1s;
	overflow: hidden;
    
}

.buttonTwo:hover {
    cursor: pointer;
} 

.buttonTwo p {
   margin: 0;
   position: relative;
   z-index: 3;
}

/* .buttonTwo:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #E2D7A6;
	z-index: 1;
	transform: translateY(-100%);
	transition: all 1s;
}

.buttonTwo:hover:before {
	transform: translateY(0);
} */

