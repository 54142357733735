.banner {
    width: 100%;
    /* min-height: 46rem; */
    min-height: fit-content;
    position: relative;
    text-align: center;
}

.bannerContentContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

.bannerSecondaryTitle {
    color: white;
}

.bannerButton {
    margin: 0 2rem;
}

.bannerVideo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}

.bannerVideoContent {
    height: 100%;
    width: 100%;
    object-fit: cover;
}