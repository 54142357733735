/* ----- INTRO SECTION STYLES ----- */
.introSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.introSectionLeft {
    background-color: #E2D7A6;
    text-align: center;
    padding: 3rem;
}

.introSectionRightImageContainer {
    width: 100%;
    height: 22rem;
}

.introSectionRightImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .introSection {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

/* ----- BEST IN HOUSE SECTION ----- */
.bestInHouseSection {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.bestInHouseItem {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: fit-content;
}

.bestInHouseItemImageContainer {
    width: 100%;
    height: 100%;
}

.bestInHouseItemTitle {
    color: white;
}

.bestInHouseItemDescription {
    background-color: #292929;
    padding: 2rem;
    order: -1;
}

.bestInHouseItemDescriptionParagraph {
    color: white;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .bestInHouseItem {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 50rem) {
    
}

/* ----- DAILY SPECIALS SECTION ----- */
.dailySpecialsSection {
    padding: 5rem;
    text-align: center;
    background-image: url('../assets/images/wooden_table.jpeg');
    background-size: cover;
}

.dailySpecialsSectionContainer {
    background-image: url('../assets/images/paper_texture.avif');
    background-size: cover;    
    padding: 0.5rem;
    border-radius: 20px;
 
    height: 100%;
    width: 100%;
    max-width: 50rem;
    box-sizing: border-box;
    margin: auto;
    box-shadow: 10px 10px 10px #1d1d1d;
}

.dailySpecialItemSectionContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.dailySpecialItemSection {
    margin: 0 2rem;
}

.dayTitle {
    margin: 0;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .aboutUsSectionContent {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 50rem) {
    .dailySpecialsSection {
        padding: 2rem;
    }

    .dailySpecialItemSectionContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* ----- ABOUT US SECTION ----- */
.aboutUsSection {
    background-color: #EEEEED;
    width: 100%;
    height: max-content;
    padding: 2rem 0;
    text-align: center;
}

.aboutUsSectionTitle {
    color: black;
    margin: 0;
}

.aboutUsSectionContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.aboutUsSectionContentTitle {
    color: black;
}

.aboutUsSectionContentLeft {
    padding: 1rem;
}

.aboutUsSectionContentRight {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutUsSectionImageContainer {
    width: 90%;
    height: max-content;
    border-radius: 25px;
    box-shadow: 10px 10px 10px #1d1d1d;

    /* Child image adjustment */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.introSectionRightImage {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .aboutUsSectionContent {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 50rem) {
    
}

/* ----- GIFT CARD SECTION ----- */
.giftCardSection {
    background-image: url('../../src/assets/images/gift_card_background.jpg');
    background-position: center;
    background-size: cover;

    height: 50rem;
    padding: 2rem;
    text-align: center;

    position: relative;
}

.giftCardSectionTitle {
    color: white;
}

.gitCardImage {
    width: 22rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.giftCardButton {
    position: absolute;

    top: 75%;
    transform: translate(-50%, -50%);
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .giftCardSection {    
        height: 40rem;
    }
}

@media screen and (max-width: 50rem) {
    .giftCardSection {    
        height: 35rem;
    }

    .giftCardButton {
        top: 85%;
    }
}

/* ----- CUSTOMER REVIEWS ----- */
.customerReviewsSection {
    background-color: #E2D7A6;
    text-align: center;
    width: 100%;
    height: fit-content;
    padding: 2rem;

    box-sizing: border-box;
}

.customerReviewsSectionTitle {
    margin: 0;
}

.customerReviewsContainer {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-content: center;
    margin: 2rem auto;
    gap: 2rem;
}

.customerReviewsContainerMobile {
    display: none;
}

/* --- Media Queries --- */
@media screen and (max-width: 80rem) {
    .customerReviewsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 50rem) {
    .customerReviewsContainer {
        display: none;
    }

    .customerReviewsContainerMobile {
        display: block;
    }
}

