.footer {
    width: 100%;
    height: fit-content;
    background-color: #292929;
    padding: 2rem 0;
    text-align: center;
    color: white;
}

.footerContentContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.mapContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mapStyles {
    width: 95%;
}

.addressTitle {
    color: #E2D7A6;
}

.addressText {
    color: white;
}

.logoContainer {
    width: 4rem;
    display: inline-block;
    margin: 1rem;
}

.logoContainer img {
    width: 100%;
}

.companyNameLink {
    color: white;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .footer {
        padding: 2rem 1rem;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 50rem) {
    .footerContentContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .mapContainer {
        order: 1;
    }
}