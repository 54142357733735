.callButton {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 4rem;
    background-color: rgb(192, 30, 30);
    z-index: 1000;
}

.callButton:hover {
    cursor: pointer;
}

.callNowTitle {
    position: absolute;
    font-size: 2rem;
    color: white;
    margin: 0;
    padding: 0;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* --- Media Queries --- */
@media screen and (max-width: 80rem) {
   
}

@media screen and (max-width: 50rem) {
    .callButton {
        display: block;
    }
}
